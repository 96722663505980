import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/base/layout"
import Article from "../components/section/article";

export default function Pricing() {
  const data = useStaticQuery(query);
  return (
    <Layout seo={data.strapiPricing.seo} slug={data.strapiPricing.slug_fr}  lang="fr" currentId="3">
      <Article list={data.strapiPricing.article} lang="fr" />
    </Layout>
  );
}

const query = graphql`
  query {
    strapiPricing {
      slug_fr
      seo {
        meta_title_fr
        meta_description_fr
      }
      article {
        type
        subtext_fr
        text_fr
        button {
          label
          url
        }
      }
    }
  }
`;